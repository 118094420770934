import React from "react";

function index() {
  return (
    <>
      <section className="resume-section" id="awards">
        <div className="resume-section-content">
          <h2 className="mb-5">Awards & Certifications</h2>
          <ul className="fa-ul mb-0">
            <li>
              <span className="fa-li">
                <i className="fas fa-trophy text-warning"></i>
              </span>
              2<sup>nd</sup>
              Place - Matellio Science Practical (Radar System) - 2023
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-trophy text-warning"></i>
              </span>
              1<sup>st</sup>
              Place - Matellio Hackethon - 2021
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-certificate text-warning"></i>
              </span>
              Python many courses Certification from DataCamp
            </li>{" "}
            <li>
              <span className="fa-li">
                <i className="fas fa-certificate text-warning"></i>
              </span>
              Java, Ruby, HTML Certification from SoloLearn
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-certificate text-warning"></i>
              </span>
              C, CPP, java Certification from Dematics
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-trophy text-warning"></i>
              </span>
              1<sup>st</sup>
              Place - Inter School Volleyball - Competition
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default index;
